import React, { lazy, Suspense, useEffect, useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { checkAutoLogin } from "./services/AuthService";
import { MetaMaskContext } from "./context/MetaMaskContext";
import Error404 from "./jsx/pages/Errors/Error404";
// Common Styles
import "./assets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./styles/css/main.css";

const Home = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Home/Home")), 500);
  });
});

function App(props) {
  const MMContext = useContext(MetaMaskContext);
  const [autoLogin, setAutoLogin] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const _autologin = checkAutoLogin(dispatch, props.history, MMContext);
    setAutoLogin(_autologin);
  }, []);

  useEffect(() => {
    if (autoLogin) {
      // Connect
      MMContext.connect();
    }
  }, [autoLogin]);

  let routes = (
    <Switch>
			<Route path="/:urlCode/:dropId" component={Home} />
      <Route path="/:urlCode" component={Home} />
			<Route component={Error404} />
    </Switch>
  );

  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {routes}
      </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(connect(mapStateToProps)(App));
