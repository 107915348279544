import { CONFIRMED_GET_NFT, ERROR_GET_NFT, CLEAN_NFT, CONFIRMED_NFT_PAID } from "../actions/NftsTypes";

const initialState = {
  nft: null,
	nftPaid: false,
  error: null,
};

export default function NftsReducer(state = initialState, actions) {
  if (actions.type === CONFIRMED_GET_NFT) {
    return {
      ...state,
      nft: actions.payload,
      error: null,
    };
  } else if (actions.type === CONFIRMED_NFT_PAID) {
    return {
      ...state,
      nftPaid: true,
    };
  } else if (actions.type === ERROR_GET_NFT) {
    return {
      ...state,
      error: actions.payload,
    };
  } else if (actions.type === CLEAN_NFT) {
    return {
      ...state,
      nft: null,
			nftPaid: false,
      error: null,
    };
  }

  return state;
}
