import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import SimpleReactLightbox from "simple-react-lightbox";
import MetaMaskContext from './context/MetaMaskContext';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

function getLibrary(provider) {
	const library = new Web3Provider(provider);
	library.pollingInterval = 12000;
	return library;
}

ReactDOM.render(
	<React.StrictMode>
		<Web3ReactProvider getLibrary={getLibrary}>
			<Provider store = {store}>
				<SimpleReactLightbox>
					<BrowserRouter basename='/'>
						<MetaMaskContext>
							<App />
						</MetaMaskContext>
					</BrowserRouter>
				</SimpleReactLightbox>
			</Provider>
		</Web3ReactProvider>
	</React.StrictMode>,
  document.getElementById("root")
);
