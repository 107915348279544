export const networkParams = {
    ONE: {
        chainId: "0x63564c40",
        rpcUrls: ["https://api.harmony.one"],
        chainName: "Harmony Mainnet",
        nativeCurrency: { name: "ONE", decimals: 18, symbol: "ONE" },
        blockExplorerUrls: ["https://explorer.harmony.one"],
        iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"]
    },
    CELO: {
        chainId: "0xa4ec",
        rpcUrls: ["https://forno.celo.org"],
        chainName: "Celo Mainnet",
        nativeCurrency: { name: "CELO", decimals: 18, symbol: "CELO" },
        blockExplorerUrl: ["https://explorer.celo.org"],
        iconUrls: [
            "https://celo.org/images/marketplace-icons/icon-celo-CELO-color-f.svg"
        ]
    },
    POLYGON_MAINNET: {
        chainId: '0x89', // utils.hexValue(137),
        chainName: "Matic (Polygon) Mainnet", 
        nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
        rpcUrls: [
            "https://polygon-rpc.com",
            "https://rpc-mainnet.matic.network",
            "https://matic-mainnet.chainstacklabs.com",
            "https://rpc-mainnet.maticvigil.com",
            "https://rpc-mainnet.matic.quiknode.pro",
            "https://matic-mainnet-full-rpc.bwarelabs.com",
        ],
        blockExplorerUrls: ["https://www.polygonscan.com/"],
        iconUrls: [
            "https://aldea.games/img/icons/polygon.png",
            "https://aldea.games/img/icons/polygon2.png"
        ],
    },
    MUMBAI_TESTNET: {
        chainId: '0x13881', //utils.hexValue(80001),
        chainName: "Matic (Polygon) Mumbai Testnet",
        nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
        rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
        iconUrls: [
            "https://aldea.games/img/icons/polygon.png",
            "https://aldea.games/img/icons/polygon2.png"
        ],
    },    
 };