import { 
	MAIN_INITIALIZED_ACTION, 
	LOADING_REQUEST_TOGGLE_ACTION,
	SET_URL_PARAMS_ACTION,
	SET_GOTO_FORM_ACTION,
} from "../actions/MainTypes";

const initialState = {
	initialized: false,
	showLoadingForRequest: false,
	urlParams: null,
	gotoForm: null,
};

export default function MainReducer(state = initialState, actions) {

	if (actions.type === MAIN_INITIALIZED_ACTION) {
		return {
				...state,
				initialized: actions.payload,
		};
	} else if (actions.type === LOADING_REQUEST_TOGGLE_ACTION) {
			return {
					...state,
					showLoadingForRequest: actions.payload,
			};
	} else if (actions.type === SET_URL_PARAMS_ACTION) {
		return {
				...state,
				urlParams: actions.payload,
		};
	} else if (actions.type === SET_GOTO_FORM_ACTION) {
		return {
				...state,
				gotoForm: actions.payload,
		};
	}
	
  return state;
}
