import {
    signinConfirmedAction,
    logoutAction,
} from '../store/actions/AuthActions';

export function saveTokenInLocalStorage(tokenDetails) {
	if (tokenDetails.expiresIn > 0) {
    tokenDetails.expireDate = new Date(
			new Date().getTime() + tokenDetails.expiresIn * 1000,
		);
	} 
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history, MMContext) {
	if (timer > 0)
		setTimeout(() => {
				console.log('runLogoutTimer > Logout');
				dispatch(logoutAction(history, MMContext));
		}, timer);
}

export function checkAutoLogin(dispatch, history, MMContext) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logoutAction(history, MMContext));
        return false;
    }

		if (!!tokenDetails.expireDate) {
			tokenDetails = JSON.parse(tokenDetailsString);
			let expireDate = new Date(tokenDetails.expireDate);
			let todaysDate = new Date();
			if (todaysDate > expireDate) {
					dispatch(logoutAction(history, MMContext));
					return false;
			}
			const timer = expireDate.getTime() - todaysDate.getTime();
			runLogoutTimer(dispatch, timer, history, MMContext);
		}
		
    dispatch(signinConfirmedAction(tokenDetails));

    return true;
}
