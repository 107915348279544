import { CONFIRMED_GET_DROPS, ERROR_GET_DROPS, SELECTED_DROP } from "../actions/DropsTypes";

const initialState = {
  drops: null,
  error: null,
	drop: null,
};

export default function DropsReducer(state = initialState, actions) {
  if (actions.type === CONFIRMED_GET_DROPS) {
    return {
      ...state,
      drops: actions.payload,
      error: null,
    };
  } else if (actions.type === ERROR_GET_DROPS) {
    return {
      ...state,
      error: actions.payload,
    };
  } else if (actions.type === SELECTED_DROP) {
    return {
      ...state,
      drop: actions.payload,
    };
  }

  return state;
}
