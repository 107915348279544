import {
    SIGNIN_CONFIRMED_ACTION,
    LOGOUT_ACTION,
} from '../actions/AuthTypes';

const initialState = {
    auth: {
        active: false,
        account: null,
        chainId: null,
        expiresIn: '',
    },
    errorMessage: '',
    successMessage: '',
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signin Successfully Completed',
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                active: false,
                account: null,
                chainId: null,
								expiresIn: '',
            },
        };
    }

    return state;
}

    
