import React, { createContext, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {  useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
// import sigUtil from '@metamask/eth-sig-util';
// import { SignTypedDataVersion, recoverTypedSignature } from '@metamask/eth-sig-util';
import {
    signinAction,
		logoutAction,
} from '../store/actions/AuthActions';
import { injected } from '../utils/WalletConnectors';
import { networkParams } from "../store/networks";
import swal from 'sweetalert';

export const MetaMaskContext = createContext();

export const MetaMaskContextProvider = ({ children }) => {
    const { chainId, account, activate, active, deactivate, library, connector, error } = useWeb3React();
    const [isActive, setIsActive] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false); // Should disable connect button while connecting to MetaMask
    const [isInstalled, setIsInstalled] = useState(false);
    const [truncatedAccount, setTruncatedAccount] = useState(false);
    
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated = useSelector((state) => state.auth.auth.active);
    const currentAccount = useSelector((state) => state.auth.auth.account);
    const currentChainId = useSelector((state) => state.auth.auth.chainId);

    useEffect(() => {
        const _isInstalled = (typeof window.ethereum !== 'undefined');
        console.log('MetaMask installed: ', _isInstalled);
        setIsInstalled(_isInstalled);
    }, [])

    // Check when App is Connected or Disconnected to MetaMask
    useEffect(() => {
        console.log('App connected with MetaMask ', active);
        setIsActive(active)
        if (active)
            switchNetwork()
    }, [active])

		useEffect(() => {
				if (isActive) {        
						dispatch(signinAction(account, chainId, history, this));
				}
		}, [ isActive ]);

    useEffect(() => {
        async function checkChanges() {
            // console.log('isAuthenticated', isAuthenticated);
            // console.log('active', active);
            // console.log('account', account, currentAccount);
            // console.log('chainId', chainId, currentChainId);
            if (isAuthenticated) {
                if (!active)  {
                    await disconnect();
                    dispatch(logoutAction(history));
                }
                else {
                    if (account != currentAccount) {
                        swal("Accound changed", "Be careful. You have just changed the account", "warning");
                    }
                    else if (chainId != currentChainId) {
                        swal("Network changed", "Be careful. You have just changed the network", "warning");                    
                    }
                }
            }
        }
        checkChanges();

        // Truncated account
        let _truncatedAccount = '';;
        if (!!account) {
            _truncatedAccount = account.substr(0, 5)+ '...' + account.substr(account.length - 4);
        }
        setTruncatedAccount(_truncatedAccount);

    }, [active, account, chainId])

    // Check errors
    useEffect(() => {
        if (error && error.code == -32002)
            swal("Metamask requires your attention", "Please, you should have a look at your Metamask extension. Maybe there is some pending action", "info");
    }, [error])

    // Connect to MetaMask wallet
    const connect = async () => {
        console.log('Connecting to MetaMask wallet...');
        if (!isInstalled) {
            swal("Metamask is NOT installed", "Please, visit metamask.io to install it. Thanks", "warning");
            return;
        }

        setIsConnecting(true);
        try {
            await activate(injected).then(() => {
                setIsConnecting(false)
            }, error => {
                console.log('Error on connecting: ', error)
            })
            .catch((error) => {
                console.log('Error on connecting: ', error)
            })
        } catch(error) {
            console.log('Error on connecting: ', error)
        }
    }

    // Disconnect from Metamask wallet
    const disconnect = async () => {
        console.log('Disconnecting wallet...')
        try {
            await deactivate()
        } catch(error) {
            console.log('Error on disconnnect: ', error)
        }
    }

    const switchNetwork = async () => {
        console.log('Switching network...', library)
        try {
            await library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: networkParams.POLYGON_MAINNET.chainId }]
            });
            } catch (switchError) {
                console.log('Error switching switchError: ', switchError.code)
                if (switchError.code === 4902) {
                    try {
                        await library.provider.request({
                            method: "wallet_addEthereumChain",
                            params: [networkParams.POLYGON_MAINNET]
                        });
                    } catch (error) {
                        console.log('Error switching network: ', error)
                    }
                }
            }
    };    

    const values = useMemo(
        () => ({
            chainId,
            account,
            isActive,
            isConnecting,
            connect,
            disconnect,
            library,
            isInstalled,
            truncatedAccount,
						//sigUtil,
        }),
        [
            chainId, 
            account, 
            isActive, 
            isConnecting, 
            library, 
            isInstalled, 
            truncatedAccount,
        ]
    )

    return <MetaMaskContext.Provider value={
        values
    }>{children}</MetaMaskContext.Provider>
}

export default MetaMaskContextProvider;
