import {
    runLogoutTimer,
    saveTokenInLocalStorage,
} from '../../services/AuthService';
import {
    SIGNIN_CONFIRMED_ACTION,
    LOGOUT_ACTION,
} from './AuthTypes';

export function signinAction(account, chainId, history, MMContext) {
    return (dispatch) => {
        const tokenDetails = {
            active: true,
            account,
            chainId,
            expiresIn: 0, // 600 // seconds
        };
        saveTokenInLocalStorage(tokenDetails);
        runLogoutTimer(
            dispatch,
            tokenDetails.expiresIn * 1000,
            history, 
            MMContext,
        );
        dispatch(signinConfirmedAction(tokenDetails));
        //history.push('/');   
    };
}

export function logoutAction(history, MMContext) {
    return async (dispatch) => {
        if (!!MMContext) {
            await MMContext.disconnect();
        }
        localStorage.removeItem('userDetails');
        //history.push('/');

        dispatch(logoutConfirmedAction());
    };
}

export function logoutConfirmedAction(data) {
    return {
        type: LOGOUT_ACTION,
    };
}

export function signinConfirmedAction(data) {
    return {
        type: SIGNIN_CONFIRMED_ACTION,
        payload: data,
    };
}
