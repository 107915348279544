import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import { AuthReducer } from './reducers/AuthReducer';
import MainReducer from './reducers/MainReducer';
import DropsReducer from './reducers/DropsReducer';
import NftsReducer from './reducers/NftsReducer';

const middleware = applyMiddleware(thunk);
const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
		form: reduxFormReducer,	
    auth: AuthReducer,
    main: MainReducer,
    drops: DropsReducer,
		nfts: NftsReducer,
});

export const store = createStore(reducers,  composeEnhancers(middleware));
